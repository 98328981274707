<template>
  <v-container fluid>
    <v-row
      justify="end"
      no-gutters>
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="onBack()">
          <v-icon>
            mdi-chevron-left
          </v-icon>
          <span>
            ย้อนกลับ
          </span>
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="warning"
          depressed
          :ripple="false"
          style="width: fit-content;"
          @click="gotoEdit()">
          <v-icon>
            mdi-square-edit-outline
          </v-icon>
          <span>
            แก้ไขข้อมูล
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row class="form-container rounded-lg">
          <v-col cols="12">
            <h3>
              ข้อมูลโปรโมชั่น
            </h3>
            <v-divider class="mt-3" />
          </v-col>
          <v-col cols="12">
            <image-uploader
              v-model="formData.imageUrls"
              :max-file="1"
              square
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="pt-2 pb-1">
            <p class="mb-1">
              ชื่อโปรโมชั่น <span class="primary--text">(TH)</span>
            </p>
            <v-text-field
              v-model="formData.name"
              placeholder="โค้ดส่วนลด 500 บาท"
              :rules="textBoxRules"
              color="primary"
              outlined
              dense
              required
              hide-details
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="pt-2 pb-1">
            <p class="mb-1">
              ชื่อโปรโมชั่น <span class="primary--text">(EN)</span>
            </p>
            <v-text-field
              v-model="formData.translate.name"
              placeholder="Discount 500 ฿"
              :rules="textBoxRules"
              color="primary"
              outlined
              dense
              required
              hide-details
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              โค้ด
            </p>
            <v-text-field
              v-model="formData.code"
              :rules="textBoxRules"
              placeholder="ATT24"
              color="primary"
              outlined
              dense
              required
              hide-details
              disabled
              append-icon="mdi-dice-multiple-outline" />
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              ราคาทัวร์ขั้นต่ำ
            </p>
            <v-text-field
              v-model.number="formData.minPrice"
              color="primary"
              outlined
              dense
              required
              hide-details
              append-icon="mdi-currency-thb"
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              มูลค่า
            </p>
            <v-text-field
              v-model.number="formData.amount"
              color="primary"
              outlined
              dense
              required
              hide-details
              disabled
              :append-icon="discountIcon()" />
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              คำอธิบาย <span class="primary--text">(TH)</span>
            </p>
            <v-text-field
              v-model="formData.description"
              color="primary"
              outlined
              dense
              required
              hide-details
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              คำอธิบาย <span class="primary--text">(EN)</span>
            </p>
            <v-text-field
              v-model="formData.translate.description"
              color="primary"
              outlined
              dense
              required
              hide-details
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              ระยะเวลาการใช้คูปอง
            </p>
            <v-row no-gutters>
              <v-col
                cols="12"
                md="5">
                <div
                  class="d-flex flex-row align-center"
                  style="gap: 0.5rem;">
                  <DatePickerDialog
                    v-model="formData.startDate"
                    disabled />
                  <TimePickerDialog
                    v-model="formData.startDate"
                    disabled />
                </div>
              </v-col>
              <v-col
                cols="12"
                md="2">
                <div class="text-center my-2 mt-md-2 mb-md-0">
                  ถึง
                </div>
              </v-col>
              <v-col
                cols="12"
                md="5">
                <div
                  class="d-flex flex-row align-center"
                  style="gap: 0.5rem;">
                  <DatePickerDialog
                    v-model="formData.endDate"
                    disabled />
                  <TimePickerDialog
                    v-model="formData.endDate"
                    disabled />
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-4" />
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              แพ็กเกจที่ร่วมรายการ
            </p>
            <v-row>
              <template v-for="(p, i) in formData.forCountries">
                <v-col
                  :key="`for-continent-${i}`"
                  cols="12"
                  md="6">
                  <div class="mb-1">
                    ทวีป
                  </div>
                  <v-autocomplete
                    v-model="formData.forCountries[i].continent"
                    :items="continents"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    placeholder="โปรดเลือก"
                    hide-details
                    required
                    disabled />
                </v-col>
                <v-col
                  :key="`for-country-${i}`"
                  cols="12"
                  md="6">
                  <div class="mb-1">
                    ประเทศ
                  </div>
                  <div
                    class="d-flex flex-row align-center"
                    style="gap: 1rem;">
                    <v-autocomplete
                      v-model="formData.forCountries[i].country"
                      :items="getFilteredCountries(formData.forCountries[i].continent)"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense
                      placeholder="โปรดเลือก"
                      hide-details
                      required
                      disabled />
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              จำนวนโค้ดโปรโมชั่น
            </p>
            <v-radio-group
              v-model="formData.isLimit"
              hide-details
              disabled
              class="mt-0">
              <v-radio :value="true">
                <template #label>
                  <v-row align="center">
                    <v-col cols="auto">
                      จำนวนโค้ดโปรโมชั่น
                    </v-col>
                    <v-col cols="auto">
                      <v-text-field
                        v-if="formData.isLimit"
                        v-model.number="formData.maxUsed"
                        :rules="formData.isLimit ? textBoxRules : []"
                        placeholder="ใส่จำนวนคูปองที่ใช้ได้"
                        type="number"
                        color="primary"
                        outlined
                        dense
                        required
                        hide-details
                        disabled />
                    </v-col>
                  </v-row>
                </template>
              </v-radio>
              <v-radio :value="false">
                <template #label>
                  <span>
                    ไม่จำกัดจำนวน
                  </span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              จำนวนการใช้งาน / คน
            </p>
            <v-radio-group
              v-model="formData.isLimitPerMember"
              hide-details
              disabled
              class="mt-0">
              <v-radio :value="true">
                <template #label>
                  <v-row align="center">
                    <v-col cols="auto">
                      จำนวนการใช้งาน / คน
                    </v-col>
                    <v-col cols="auto">
                      <v-text-field
                        v-if="formData.isLimitPerMember"
                        v-model.number="formData.perMember"
                        :rules="formData.isLimitPerMember ? textBoxRules : []"
                        placeholder="ใส่จำนวนคูปองที่ใช้ได้"
                        type="number"
                        color="primary"
                        outlined
                        dense
                        required
                        hide-details
                        disabled />
                    </v-col>
                  </v-row>
                </template>
              </v-radio>
              <v-radio :value="false">
                <template #label>
                  <span>
                    ไม่จำกัดจำนวน
                  </span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              เงื่อนไข <span class="primary--text">(TH)</span>
            </p>
            <Editor
              v-model="formData.conditions"
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              เงื่อนไข <span class="primary--text">(EN)</span>
            </p>
            <Editor
              v-model="formData.translate.conditions"
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              วิธีการใช้โค้ด <span class="primary--text">(TH)</span>
            </p>
            <Editor
              v-model="formData.howTo"
              disabled />
          </v-col>
          <v-col
            cols="12"
            md="8">
            <p class="mb-1">
              วิธีการใช้โค้ด <span class="primary--text">(EN)</span>
            </p>
            <Editor
              v-model="formData.translate.howTo"
              disabled />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import PrivilegesProvider from '@/resources/privileges.provider'
import CountriesProvider from '@/resources/countries.provider'
import DatePickerDialog from '@/components/DatePickerDialog'
import TimePickerDialog from '@/components/TimePickerDialog'
import ImageUploader from '@/components/ImageUploader.vue'
import Editor from '@/components/Editor.vue'
import { translateCountry, translateContinent } from '@/utils/translates.utils'

const PrivilegesService = new PrivilegesProvider()
const CountriesService = new CountriesProvider()

export default {
  components: {
    DatePickerDialog,
    TimePickerDialog,
    ImageUploader,
    Editor
},
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    formData: {
      name: '',
      imageUrls: [],
      description: '',
      specialText: '',
      startDate: dayjs().format('YYYY-MM-DD HH:mm'),
      endDate: dayjs().format('YYYY-MM-DD HH:mm'),
      code: '',
      minPrice: 0,
      maxPrice: 0,
      amount: 0,
      maxUsed: -1,
      usedCount: 0,
      type: 'promotion',
      discountType: 'baht',
      firstPurchase: false,
      perMember: -1,
      forRank: '',
      forMembers: [],
      forCountries: [],
      conditions: '',
      howTo: '',
      translate: {
        name: '',
        description: '',
        specialText: '',
        conditions: '',
        howTo: ''
      },
      isLimit: false,
      isLimitPerMember: false
    },
    continents: [],
    countries: []
  }),
  computed: {
    itemId () {
      return this.$route.params?.id || null
    }
  },
  async mounted () {
    await this.getCountries()

    if (this.itemId) {
      this.getItemById()
    }
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'PrivilegeList' })
    },
    gotoEdit () {
      this.$router.push({
        name: 'PrivilegeEdit',
        params: {
          id: this.itemId
        }
      })
    },
    discountIcon () {
      return this.formData.discountType === 'percent' ? 'mdi-percent-outline' : 'mdi-currency-thb'
    },
    getFilteredCountries (continent) {
      const selectedCountries = this.formData.forCountries.map((c) => c.country)

      return [
        {
          text: 'ทั้งหมด',
          value: '',
          continent: '',
          disabled: selectedCountries.includes('')
        },
        ...this.countries
          .filter((c) => c.continent === continent)
          .map((c) => ({
            ...c,
            disabled: selectedCountries.includes(c.value)
          }))
      ]
    },
    async getCountries () {
      try {
        this.loading = true

        const { data } = await CountriesService.getItems({
          page: 1,
          limit: 99999
        })

        const uniqueContinents = [...new Set(data.results.map((c) => c.continent))]

        const mappedContinents = uniqueContinents.map((c) => ({
          text: translateContinent(c),
          value: c
        }))
        this.continents = [
          {
            text: 'ทั้งหมด',
            value: ''
          },
          ...mappedContinents
        ]
        this.countries = data.results.map((c) => ({
          text: translateCountry(c.country),
          value: c.country,
          continent: c.continent
        }))
      } catch (error) {
        console.error('getCountries', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await PrivilegesService.getItemById(this.$route.params.id)

        this.formData = {
          ...data,
          imageUrls: data.imageUrls.map((img) => ({
            file: null,
            preview: img,
            mediaUrl: img
          })),
          isLimit: data.maxUsed !== -1,
          isLimitPerMember: data.perMember !== -1
        }
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>

<style>
.ql-toolbar.ql-snow {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ql-container.ql-snow  {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
